export default {

    SiteMode:
    {
        Normal: 'Normal',
        Admin: 'Admin',
    },
    LogTypes:
    {
        Export: 'Export',
        Add: 'Add',
        Browse: 'Browse',
        Update: 'Update',
        Delete: 'Delete',
    },
    LogSource:
    {
        General: 'General',
        Users: 'Users',
        Customers: 'Customers',
        Projects: 'Projects',
        Password: 'Password'
    },
    Role:
    {
        Admin: '1',
        Auditor: '2',
        Customer: '3',
        Supplier: '4'
    },
    CategoryTypes:
    {
        NotActivity:1,
        Activity: 2
    },
    Level0Kod:
    {
        CateogryIPCC1: '1',
        CateogryIPCC2: '2',
        CateogryIPCC3: '3',
        CateogryIPCC4: '4',
        CateogryIPCC5: '5',
        CateogryIPCC6: '6',
        IPCCNKD: '7',
        CategoryTR1: '8',
        TRNKD: '9',
        Scope1Defra: '10',
        Scope2Defra: '11',
        Scope3Defra: '12',
        DefraOoS: '13',
        DefraNKD: '14',
        DefraYogunluk: '15'
    },
    Level1Kod:
    {
        SabitYakma: '1.1',
        HareketliYakma: '2.1',
        ProsesEmisyonlari:'3.1' ,
        KacakGazlar: '4.1',
        Lulucf: '5.1',
        IthalEdilenEnerji: '6.1',
        IthalEdilenElektik: '8.1',
        Scope1Yakitlar: '10.1',
        Scope1SogutucuveDiger: '10.3',
        Scope3MalzemeKullanimi: '12.9',
        Scope3AtikBertarafi: '12.10',
        Scope3IsSeyehatleriUcuslar: '12.11',
        Scope3IsSeyehatleriDeniz: '12.13',
        Scope3IsSeyehatleriKara: '12.15',
        Scope3OtelKonaklamasi: '12.19',
        Scope3MalNakliyesi: '12.17',
        Scope3SeyehatArac: '12.15',
        Scope3SiviAtik: '12.8',
        Scope3Konaklama: '12.19',
        TRElektrik: '8.1',
        Scope2Elektrik: '11.2'
    },
    Level2Kod:
    {
        KarayoluTasimaciligi: '2.1.1',
        KacakGazlar: '4.1.1',
        KacakGazlarDefra: '10.3.1'
    },
    EmissionCategory:
    {
        DirectEmissions: '1',
        Electric: '2',
        Transportation: '3',
        PurchasedItem: '4',
        MaterialConsumption: '5',
        Oder:'6'
    },
    EmissionSources:
    {
        Musteri:'3.4.2',
        Ziyaretci:'3.4.1',
        Personel: '3.3.1',
        Ucak: '3.5.1',
        Otel: '3.5.2',
        isinmaVeElektrik: '3.5.3',
        AtikSu: '4.3.1',
        AtikCam: '4.3.2',
        OnRoad: '5.2.1'
    },
    ProtocolType:
    {
        ISO: 1,
        GHG: 2
    },
    CalculationType:
    {
        IPCC: 1,
        DEFRA: 2,
        TR: 3
    }
};
