const localStorageUtil = {
    setItem: (key, value, hasBase64 = false) => {
        try {
            let encryptedValue = value;
            if (hasBase64) {
                encryptedValue = btoa(value);
            }
            localStorage.setItem(key, encryptedValue);
        } catch (error) {
            console.error('localStorage setItem error:', error);
        }
    },

    getItem: (key, hasBase64 = false) => {
        try {
            let value = localStorage.getItem(key);
            if (value && hasBase64) {
                value = atob(value);
            }
            return value;
        } catch (error) {
            console.error('localStorage getItem error:', error);
            return null;
        }
    },

    removeItem: (key) => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error('localStorage removeItem error:', error);
            return null;
        }
    },

    clear: () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error('localStorage clear error:', error);
            return null;
        }
    }
};

export default localStorageUtil;
