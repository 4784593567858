import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import 'antd/dist/reset.css';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { LOCAL_STORAGE_KEY } from '@iso/assets/styles/constants';
import localStorageUtil from '@iso/components/utility/localStorageUtil';
import { apiStatusManagement } from '@iso/lib/helpers/apiStatusManagement';
import { buildRequestOptions } from './library/helpers/utils';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import siteConfig from './config/site.config';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';


const App = () => {
  const [modal, contextHolder] = Modal.useModal();

  const [isUILocked, setIsUILocked] = useState(false);

  let isModalVisible = false;

  useEffect(() => {
    getCurrentUIVersion();

    const interval = setInterval(() => {
      getCurrentUIVersion();
    }, isUILocked ? 30 * 1000 : 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [isUILocked]);

  async function getCurrentUIVersion() {
    await fetch(`${siteConfig.api.security.health}`, buildRequestOptions("GET"))
      .then(response => {
        const status = apiStatusManagement(response);
        return status;
      })
      .then(res => {
        if (res) {
          setIsUILocked(res.isUILocked);

          let currentVersion;
          try {
            const packageJson = require('./../package.json');
            currentVersion = packageJson.currentVersion;
          } catch (error) {
            console.log(error);
          }
          if (!currentVersion) {
            currentVersion = localStorageUtil.getItem(LOCAL_STORAGE_KEY.CURRENT_VERSION);
          }

          const regexPattern = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
          const isValid = regexPattern.test(currentVersion);

          if (isValid || !currentVersion) {
            if (typeof res.currentUIVersion === 'string' && res.currentUIVersion !== currentVersion && !isModalVisible && !res.isUILocked) {
              isModalVisible = true;
              return modal.confirm({
                title: `Yeni Sürüm - v${res.currentUIVersion}`,
                icon: <ExclamationCircleOutlined />,
                content: `Uygulamanın yeni bir sürüm mevcuttur. Güncellemek ister misiniz?`,
                okText: 'Güncelle',
                cancelText: 'Sonra Hatırlat',
                onOk: () => { localStorageUtil.setItem(LOCAL_STORAGE_KEY.CURRENT_VERSION, res.currentUIVersion); window.location.reload(true); isModalVisible = false; },
                onCancel: () => { isModalVisible = false; }
              });
            }
          }
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <Provider store={store}>
      <AppProvider>
        <GlobalStyles />
        <Routes isUILocked={isUILocked} />
        {contextHolder}
      </AppProvider>
    </Provider>
  )
};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
