export function FormatPercentage(decimalNumber, symbol = true) {
  if (typeof decimalNumber !== 'number' || isNaN(decimalNumber)) {
    return symbol ? '-' : 0;
  }

  let percentage = symbol ? '%' + (decimalNumber * 100).toString() : (decimalNumber * 100).toString();
  return percentage;
}

export default function ResultNumberFormat(value) {
  var formattingNumber = Number(value);
  var myObj = {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }

  return (formattingNumber.toLocaleString("tr-TR", myObj));
}