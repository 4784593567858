import history from '@iso/lib/helpers/history';
import { clearLocalStorage } from './utility';

export const apiStatusManagement = (response, notShowMessage) => {
  try {
    switch (response.status) {
      case 401:
        clearLocalStorage();
        return history.replace('/signin');
      case 404:
        return response.json();
      case 500:
        return history.replace('/signin');
      default:
        return response.json();
    }
  }
  catch (err) {
    return 'Unauthorized1';
  }
};