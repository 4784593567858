import React from "react";
import { Tag, Tooltip } from "antd";
import { ClockCircleOutlined, WarningOutlined } from '@ant-design/icons';
import siteConfig from "@iso/config/site.config";
import dayjs from "dayjs";
import 'dayjs/locale/tr';

dayjs.locale('tr');
export default ({ date, format = 'fullDate', warning = false, error = false, title = dayjs(date).format(format === 'fullDate' ? siteConfig.dateFormatAddTime : siteConfig.dateFormat), bordered = true }) => {
    return (
        <Tooltip title={title}>
            <Tag icon={warning || error ? <WarningOutlined /> : <ClockCircleOutlined />} color={warning ? 'warning' : error ? 'error' : "default"} bordered={bordered}>
                {dayjs(date).format(siteConfig.dateFormat)}
            </Tag>
        </Tooltip>
    );
}