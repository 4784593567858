export const colors = {
  blue: {
    deep: 'rgb(0, 121, 191)',
    light: 'lightblue',
    lighter: '#d9fcff',
    soft: '#E6FCFF',
  },
  black: '#4d4d4d',
  shadow: 'rgba(0,0,0,0.2)',
  grey: {
    darker: '#C1C7D0',
    dark: '#E2E4E6',
    medium: '#DFE1E5',
    N30: '#EBECF0',
    light: '#F4F5F7',
  },
  green: 'rgb(185, 244, 188)',
  white: 'white',
  purple: 'rebeccapurple',
  orange: '#FF991F',
};
export const grid = 8;

export const borderRadius = 2;
const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
};
const colStyle = {
  marginBottom: '16px',
};
const gutter = 16;

const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
};

export const LOCAL_STORAGE_KEY = {
  //'id_token'
  ID_TOKEN: 'scoop.it',

  //access_token
  ACCESS_TOKEN: "scoop.at",

  //expires_at
  EXPIRES_AT: 'scoop.ea',

  //last_view_date
  LAST_VIEW_DATE: 'scoop.lvd',

  //do_not_show_campaign_modal
  DO_NOT_SHOW_CAMPAIGN_MODAL: 'scoop.dnscm',

  //last_tour_time_topbar_alert
  LAST_TOUR_TIME_TOPBAR_ALERT: 'scoop.lttta',

  //active_user
  ACTIVE_USER: 'scoop.au',

  //local_data_name
  LOCAL_DATA_NAME: 'scoop.ldn',

  //current_version
  CURRENT_VERSION: 'scoop.cv',

  //itemCodes
  ITEM_CODES: "scoop.ic",

  //isUILocked
  IS_LOCKED_UI: "scoop.iuil"
}

export const URL_PARAMS = {
  TO: "to",
  FROM: "from",
  SITE_MODE: "smode",
  KEYWORD: "keyword",
  PAGE_SIZE: "pgsize",
  PAGE_INDEX: "pgindex",
  SORTING_FIELD: "sfield",
  SORTING_ORDER: "sorder",

  MODAL_CODE: "mc",
  ITEM_CODE: "ic",
  PERIOD_ID: "pi",
  GUID_ID: "gid",
  CUSTOMER_ID: "ci",

  TYPE: "typ",
  RENDER_TYPE: "rtyp",
  SEARCH_TYPE: "stype",
  PROJECT_TYPE: "ptyp",
  PROTOCOL_TYPE: 'prtyp',

  STATUS: "status",

  CATEGORY: "c",

  ROL: "r",
  KEY: "k",
  USER: "u",
  TOKEN: "token",
  APPROVE: "approve",
  VERSION: "v",

  YEAR: "yr",
  MONTH: "mo",
  CUSTOM_DATE: "cdate",

  IS_READ: "ir",
  IS_ACTIVE: "ia",
  Is_ARCHIVED: "iar",

  AMOUNT: "amt",

  ITEM_NAME: "in",
  INCLUDE_ITEMS: "ii",
  FILE_NAME: "fn",
  FILE_TYPE: "ft",
  SOURCE: "source",
  INCLUDE_EVENTS: "iev"
}

export default basicStyle;
