// const apiUrl = 'http://localhost:5000/api/';
// const apiUrl = 'http://life-enerji-api.karyasmd.com/api/';
const apiUrl = 'https://scoop-api.life-climate.com/api/';

export default {
  siteName: 'SCOOP',
  siteIcon: 'ion-flash',
  footerText: `Karya SMD © ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  nullOrEmptySearchItem: '(YOK)',
  api: {
    report: {
      postApprove: apiUrl + 'customers/approve-status',
    },
    core: {
      getCountry: apiUrl + 'core/countries',
      getlevel0: apiUrl + 'core/level0s',
      saveEmissionStatements: apiUrl + 'core/versioning-factors',
      getEmissionStatementsByCategoryKod: apiUrl + 'core/emission-statements',
      getFuelTypes: apiUrl + 'core/fuel-types',
      getFuelTypesFiltered: apiUrl + 'core/fuel-types',
      getLevel2sByLevel1Kod: apiUrl + 'core/level2s',
      getUniqueVersions: apiUrl + 'core/unique-versions',
      getFuelTypeUnits: apiUrl + 'core/fuelTypes/unit'
    },
    customer: {
      deleteCustomer: apiUrl + 'customers/delete',
      searchCustomers: apiUrl + 'customers/search',
      getCustomers: apiUrl + 'customers',
      getCustomer: apiUrl + 'customers',
      getActiveCustomers: apiUrl + 'customers/active-search',
      postCustomer: apiUrl + 'customers',
      activityDataUpdate: apiUrl + 'customer/emissions/update',
      periodComparison: apiUrl + 'customer/periods/comparison',
      periodComparisonPie: apiUrl + 'customer/periods/comparison-pie',
      activePeriodDataCount: apiUrl + 'customer/periods/data-count',
      getActivityData: apiUrl + 'customer/emissions',
      saveActivityData: apiUrl + 'customer/emissions',
      delteActivityData: apiUrl + 'customer/emissions'
    },
    users: {
      postUsers: apiUrl + 'security/users/search',
      postUser: apiUrl + 'security/users',
      deleteUser: apiUrl + 'security/users/',
      getRestrictedUsers: apiUrl + 'security/restricted-users/search',
      postRestrictedUser: apiUrl + 'security/restricted-users',
      getActiveAuditors: apiUrl + 'security/auditor-users',
      getMenuTree: apiUrl + 'security/menu-tree',
      getMenuQuickSearchList: apiUrl + 'security/menu-tree-quick-search-list'
    },
    security: {
      getNotificationType: apiUrl + 'security/notifications/types',
      getRoles: apiUrl + 'security/roles',
      getUser: apiUrl + 'security/users/',
      getCustomerUser: apiUrl + 'security/user-customer/',
      getAuditorUser: apiUrl + 'security/user-auditor/',
      postAuthenticate: apiUrl + 'security/authenticate',
      logout: apiUrl + 'security/logout',
      postChangePassword: apiUrl + 'security/change-password',
      getLogSources: apiUrl + 'security/logs/sources',
      getLogTypes: apiUrl + 'security/logs/types',
      postLog: apiUrl + 'security/logs/search',
      postSaveLog: apiUrl + 'security/logs',
      postForgotPassword: apiUrl + 'security/forgot-password',
      postNotification: apiUrl + 'security/notifications/search',
      getNotificationByUserId: apiUrl + 'security/users/{userId}/notifications',
      postIsRead: apiUrl + 'security/notifications/{notificationId}/read',
      getGeneratePassword: apiUrl + 'security/generate-password',
      getSessionProperties: apiUrl + 'security/session-properties',
      health: apiUrl + 'security/diagnostics/health'
    },
    file: {
      postExcel: apiUrl + 'files/upload-excel',
      uploadFile: apiUrl + 'files/customers/upload',
      showFile: apiUrl + 'files/customers/download',
      getFilesSearch: apiUrl + 'files/search',
      deleteFileByGuidId: apiUrl + 'files'
    },
    project: {
      getProjects: apiUrl + 'projects',
      saveProject: apiUrl + 'projects/save',
      getUserProjects: apiUrl + 'projects/user',
      getProjectById: apiUrl + 'projects/{projectId}',
      deleteProjectById: apiUrl + 'projects/{projectId}',
      getProjectByCustomerIds: apiUrl + 'projects/customer-ids',
      pinProject: apiUrl + 'projects/{projectId}/pin',
    },
    lookup: {
      protocolTypes: apiUrl + 'projects/lookup/protocol-types',
    }
  },
  timeDisplayFormat: 'HH:mm',
  dateFormat: 'DD-MM-YYYY',
  riskDateFormat: 'YYYY-MM-DD',
  dateFormatAddTime: 'DD-MM-YYYY HH:mm:ss',
  dateFormatAddTimeExtendedAccountPage: 'DD-MM-YYYY HH:mm:ss',
  pastDateIntervals: [
    { key: 'today', text: 'Bugün', diff: 0 },
    { key: 'yesterday', text: 'Dün', diff: -1 },
    { key: 'last3Days', text: 'Son 3 Gün', diff: -3 },
    { key: 'last7Days', text: 'Son 1 Hafta', diff: -7 },
    { key: 'last30Days', text: 'Son 1 Ay', diff: -30 },
    { key: 'last90Days', text: 'Son 3 Ay', diff: -90 },
    { key: 'last180Days', text: 'Son 6 Ay', diff: -180 },
    { key: 'last365Days', text: 'Son 1 Yıl', diff: -366 },
  ],
  futureDateIntervals: [
    { key: 'next7Days', text: 'Gelecek 1 Hafta', diff: 7 },
    { key: 'next15Days', text: 'Gelecek 15 Gün', diff: 15 },
    { key: 'next30Days', text: 'Gelecek 1 Ay', diff: 30 },
    { key: 'next90Days', text: 'Gelecek 3 Ay', diff: 90 },
    { key: 'next180Days', text: 'Gelecek 6 Ay', diff: 180 },
  ],
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
