const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SET_SESSION_PROPERTIES: 'SET_SESSION_PROPERTIES',

  logout: () => ({
    type: actions.LOGOUT,
  }),

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION,
  }),

  loginSuccess: (sessionProperties) => ({
    type: actions.LOGIN_SUCCESS,
    sessionProperties,
  }),

  loginError: () => ({
    type: actions.LOGIN_ERROR,
  }),
};

export default actions;
