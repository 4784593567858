export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

const actions = {
  CHANGE_COLLAPSE: 'CHANGE_COLLAPSE',
  CLOSE_COLLAPSE: 'CLOSE_COLLAPSE',
  OPEN_COLLAPSE: 'OPEN_COLLAPSE',
  TOGGLE_ALL: 'TOGGLE_ALL',
  SELECTED_KEYS: 'SELECTED_KEYS',
  OPEN_KEYS: 'OPEN_KEYS',

  changeCollapse: () => ({
    type: actions.CHANGE_COLLAPSE,
  }),

  closeCollapse: () => ({
    type: actions.CLOSE_COLLAPSE,
  }),

  openCollapse: () => ({
    type: actions.OPEN_COLLAPSE,
  }),

  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },

  setSelectedKeys: (selectedKeys) => ({
    type: actions.SELECTED_KEYS,
    selectedKeys,
  }),

  setOpenKeys: (openKeys) => ({
    type: actions.OPEN_KEYS,
    openKeys,
  }),
};

export default actions;
