import actions from './actions';

const initState = { sessionProperties: { isAuthenticated: false } };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        sessionProperties: action.sessionProperties,
      };
    case actions.SET_SESSION_PROPERTIES:
      return {
        ...state,
        sessionProperties: action.sessionProperties,
      };
    case actions.LOGOUT:
      return initState;
    case actions.LOGIN_ERROR:
      return {
        ...state,
        sessionProperties: { isAuthenticated: false },
      };
    default:
      return state;
  }
}
