import React from 'react';

import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import { Button, Tag, Tooltip, Typography } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, ContainerOutlined, LinkOutlined } from '@ant-design/icons';

import numberFormat from "@iso/config/numberFormat";
import siteConfig from "@iso/config/site.config";
import localStorageUtil from '@iso/components/utility/localStorageUtil';
import { apiStatusManagement } from '@iso/lib/helpers/apiStatusManagement';
import DateDisplay from '../../components/utility/DateDisplay';
import { FormatPercentage } from '../../config/resultNumberFormat';
import { LOCAL_STORAGE_KEY, URL_PARAMS } from '../../assets/styles/constants';
import enumerations from '../../config/enumerations';

dayjs.locale('tr');

const { Link, Text } = Typography;

//TODO: Öncelikli olarak jwtToken değerini cookie'e ata ve session properties'i uygula
async function getSessionProperties(setSessionProperties) {
    let response;
    await fetch(`${siteConfig.api.security.getSessionProperties}`, buildRequestOptions('GET'))
        .then(response => {
            const status = apiStatusManagement(response, true);
            return status;
        })
        .then(res => {
            if (typeof setSessionProperties === 'function') {
                setSessionProperties(res);
            }
            return response = res;
        })
        .catch(error => console.log(error));
    return response;
}

function checkAccessPermission(roleName, sessionProperties) {
    let rNames = null;
    if (typeof roleName === 'string') {
        rNames = [roleName];
    } else if (Array.isArray(roleName)) {
        rNames = roleName;
    } else {
        return false;
    }
    if (sessionProperties && sessionProperties.roleName) {
        let role = sessionProperties.roleName;

        if (rNames.indexOf(role) >= 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function buildRequestOptions(method = "GET", body = null) {
    let currentVersion;
    try {
        const packageJson = require('./../../../package.json');
        currentVersion = packageJson.currentVersion;
    } catch (error) {
        console.log(error);
    }

    if (!currentVersion) {
        currentVersion = localStorageUtil.getItem(LOCAL_STORAGE_KEY.CURRENT_VERSION);
    }

    const regexPattern = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
    const isValid = regexPattern.test(currentVersion);

    const requestOptions = {
        method: method,
        headers: {
            "Content-Type": "application/json",
            "X-SCOOP-Version": isValid ? currentVersion : "N/A",
            "X-SCOOP-Referer": window.location.href || "N/A",
        },
        credentials: 'include'
    };
    if (body) {
        requestOptions['body'] = JSON.stringify(body);
    }
    return requestOptions;
}

function findMenuKeys(menuItems, path) {
    for (const item of menuItems) {
        if (item.key === path) {
            return { selectedKey: item.key, openKey: null };
        }
        if (item.children) {
            const found = findMenuKeys(item.children, path);
            if (found.selectedKey) {
                return { selectedKey: found.selectedKey, openKey: item.key };
            }
        }
    }
    return { selectedKey: null, openKey: null };
}

function handleDateRangeChange(dates, dateStrings, selectedRange, setFromDate, setToDate) {
    if (Array.isArray(dates)) {
        setFromDate(dates[0]);
        setToDate(dates[1]);
    } else if (selectedRange === 'start') {
        setFromDate(dates);
    } else if (selectedRange === 'end') {
        setToDate(dates);
    } else {
        setToDate(null);
        setFromDate(null);
    }
}

function titleCase(str) {
    str.toLowerCase();
    var strAr = str.split(" ");
    for (var i = 0; i < strAr.length; i++) {
        strAr[i] = strAr[i].charAt(0).toUpperCase() + strAr[i].substring(1).toLocaleLowerCase('tr-TR');
    }
    str = strAr.join(" ");

    return str;
}

function isStrInteger(value) {
    return /^\d+$/.test(value);
}

function parseJSON(data) {
    if (typeof data === 'string') {
        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    } else if (typeof data === 'object' && data !== null) {
        return data;
    } else {
        return null;
    }
}

function formatCamelToHyphen(input) {
    return input
        .replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function mimeToExtension(mime) {
    const mimeMap = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp',
        'image/webp': 'webp'
        // Other mimetypes can be added here
    };
    return mimeMap[mime] || 'unknown';
}

function translateTabName(tabKey) {
    switch (tabKey) {
        //Protocol GHG
        case 'stationaryCombustionGHG':
            return 'Sabit Yanma';
        case 'processEmissionsGHG':
            return 'Proses Emisyonları';
        case 'fugitiveEmissionsGHG':
            return 'Kaçak Emisyonlar';
        case 'mobileCombustionGHG':
            return 'Hareketli Yanma';
        case 'indirectEnergyEmissions':
            return 'Enerji Dolaylı Sera Gazı Emisyonları';
        case 'electricityPurchasesGHG':
            return 'Satın Alınan Elektrik';
        case 'purchasedHeatEmissionsGHG':
            return 'Satın Alınan Isı ve Buhar';
        case 'purchasedGoodsEmissionsGHG':
            return 'Satın Alınan Mal ve Hizmetler';
        case 'capitalGoodsEmissionsGHG':
            return 'Sermaye Malları';
        case 'otherGHG':
            return 'Kapsam 1 ve Kapsam 2 de Yer Almayan Yakıt ve Enerji İlişkili Faaliyetler';
        case 'upstreamTransportationEmissionsGHG':
            return 'Yukarı Yönde Taşımacılık ve Dağıtım';
        case 'operationalWasteEmissionsGHG':
            return 'Operasyon Sırasında Oluşan Atıklar';
        case 'flightsGHG':
            return 'Uçuşlar';
        case 'employeeCommutingEmissionsGHG':
            return 'Çalışan Ulaşımları';
        case 'leasedAssetsEmissionsGHG':
            return 'Yukarı Yönde Kiralanan Varlıklar';
        case 'downstreamTransportationEmissionsGHG':
            return 'Aşağı Yönde Taşımacılık ve Dağıtım';
        case 'purchasingProcessingGHG':
            return 'Satın Alınan Ürünlerin İşlenmesi';
        case 'productUseEmissionsGHG':
            return 'Satın Alınan Ürünlerin Kullanımı';
        case 'eolGHG':
            return 'Satılan Ürünlerin Yaşam Döngüsü Sonu İşlemleri';
        case 'leasedAssetsDownstreamEmissionsGHG':
            return 'Aşağı Yönde Kiralanan Varlıklar';
        case 'franchiseBranchEmissionsGHG':
            return 'Franchise Şubeleri';
        case 'investmentEmissionsGHG':
            return 'Yatırımlar';
        //Protocol ISO
        case 'stationaryCombustionISO':
            return 'Sabit yakma kaynaklı doğrudan emisyonlar';
        case 'mobileCombustionISO':
            return 'Hareketli yakma kaynaklı doğrudan emisyonlar';
        case 'processEmissionsISO':
            return 'Endüstriyel süreçlerden kaynaklanan doğrudan proses emisyonları ve uzaklaştırmaları';
        case 'fugitiveEmissionsISO':
            return 'Antropojenik sistemlerdeki sera gazlarının sızması/kaçak oluşumu kaynaklı doğrudan emisyonlar';
        case 'lulucfEmissionsISO':
            return 'Arazi kullanımı, arazi kullanımındaki değişiklik ve ormancılık (LULUCF) faaliyetlerinden kaynaklanan doğrudan emisyonlar ve uzaklaştırmalar';
        case 'purchasedElectricityEmissionsISO':
            return 'İthal Edilen Elektrikten Kaynaklanan Dolaylı Emisyonlar';
        case 'purchasedHeatEmissionsISO':
            return 'İthal edilen enerjiden kaynaklanan dolaylı emisyonlar';
        case 'upstreamTransportationEmissionsISO':
            return 'Mal (kuruluşa gelen) Taşımacılığı veya Dağıtımından Kaynaklanan Emisyonlar';
        case 'downstreamTransportationEmissionsISO':
            return 'Mal (kuruluştan giden) Taşımacılığı veya Dağıtımından Kaynaklanan Emisyonlar';
        case 'employeeCommutingEmissionsISO':
            return 'Personelin İşe Gidiş Gelişleri Kaynaklı Emisyonlar';
        case 'clientTransportEmissionsISO':
            return 'Müşteriler ve ziyaretçilerin ulaşımı kaynaklı emisyonlar';
        case 'businessTravelEmissionsISO':
            return 'İş Seyahatleri Kaynaklı Emisyonlar';
        case 'accommodationEmissionsISO':
            return 'Konaklama Kaynaklı Emisyonlar';
        case 'purchasedMaterialsISO':
            return 'Ürünün imalatı ile ilişkili olan satın alınan hammadde/mamul/yarı mamul vb. kaynaklı emisyonlar';
        case 'capitalGoodsEmissionsISO':
            return 'Sermaye Niteliğindeki Varlıklardan (taşınır & taşınmaz) Kaynaklanan Emisyonlar';
        case 'operationalWasteEmissionsISO':
            return 'Katı ve Sıvı Atıkların Bertarafı Kaynaklı Emisyonlar';
        case 'leasedAssetsEmissionsISO':
            return 'Kiralanan Ekipmanların (kuruluş tarafından) Kullanımı Kaynaklı Emisyonlar';
        case 'purchasedGoodsEmissionsISO':
            return 'Danışmanlık, Temizlik, Bakım, Kurye, Bankacılık vb. Hizmet Alımları Kaynaklı Emisyonlar';
        case 'otherISO':
            return 'Diğer Kaynaklardan Ortaya Çıkan Dolaylı Sera Gazı Emisyonları';
        case 'productUseEmissionsISO':
            return 'Ürünün kullanımı kaynaklı emisyonlar ve uzaklaştırmalar';
        case 'leasedAssetsDownstreamEmissionsISO':
            return 'Kiraya verilen ekipmanların (kuruluşa ait) kullanımı kaynaklı emisyonlar kaynaklı emisyonlar';
        case 'eolISO':
            return 'Ürünün kullanım ömrünü tamamlamasından sonraki emisyonlar (arıtma, bertaraf, geri kazanım, vb.)';
        case 'investmentEmissionsISO':
            return 'Yatırımlar kaynaklı emisyonlar';
        default:
            return 'unknown';
    }
}

function getFileTypeExtensionFromContentType(contentType) {
    switch (contentType) {
        case 'application/pdf':
            return 'pdf';
        case 'application/msword':
            return 'doc';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'docx';
        case 'application/vnd.ms-excel':
            return 'xls';
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xlsx';
        case 'application/vnd.ms-powerpoint':
            return 'ppt';
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return 'pptx';
        case 'text/plain':
            return 'txt';
        case 'image/jpeg':
            return 'jpg';
        case 'image/png':
            return 'png';
        case 'application/zip':
        case 'application/x-zip-compressed':
            return 'zip';
        default:
            return 'txt';
    }
}

const tableFunctions = {
    renderFunctions: {
        general: {
            index: (_, record, index) => (
                index + 1
            )
        },
        userList: {
            isLocked: (item) => (
                !item ?
                    <Tag color='green' key={item} bordered={false}>Açık</Tag> :
                    <Tag color='red' key={item} bordered={false}>Kapalı</Tag>
            ),
            roleDescription: (_, record) => (
                record.role.roleDescription
            ),
            canCustomerViewFiles: (item) => (
                item ?
                    <CheckCircleTwoTone twoToneColor={"#00C853"} /> :
                    <CloseCircleTwoTone twoToneColor={"#FF1744"} />
            ),
            companyName: (item, record) => (
                record && record.customer && record.customer.companyName ? record.customer.companyName : '-'
            )
        },
        customers: {
            companyFullName: (item, record) => (
                <Link href={`/app/${record.id}/dashboard/mainForm`} >{item}</Link>
            ),
            isActive: (item) => (
                item ?
                    <Tag color='green' key={item} bordered={false}>Açık</Tag> :
                    <Tag color='red' key={item} bordered={false}>Kapalı</Tag>
            ),
            phone: (item) => {
                function formatPhoneNumber(phoneNumber) {
                    let cleaned = ('' + phoneNumber).replace(/\D/g, '');
                    if (cleaned[0] === '0') {
                        cleaned = cleaned.substring(1);
                    }

                    const match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
                    if (match) {
                        return '0 (' + match[1] + ') ' + match[2] + ' ' + match[3] + ' ' + match[4];
                    }
                    return phoneNumber;
                }

                return formatPhoneNumber(item);
            }
        },
        periods: {
            isArchived: (item) => (
                !item ?
                    <Tag color='green' key={item} bordered={false}>Açık</Tag> :
                    <Tag color='red' key={item} bordered={false}>Kapalı</Tag>
            ),
            name: (item, record) => {
                if (record.customerId) {
                    return <Link href={`/app/${record.customerId}/reports/activityData?${URL_PARAMS.PERIOD_ID}=${record.id}`} >{item}</Link>;
                }
                return '-';
            },
            companyName: (item, record) => (
                record.customer && record.customer.companyName
            ),
            protocolType: (item) => (
                item === enumerations.ProtocolType.ISO ?
                    <Tag color="magenta">ISO</Tag> :
                    <Tag color="purple">GHG</Tag>
            ),
            version: (item, record) => (
                <Tooltip title={record.isCurrentVersion ? 'Güncel Sürüm' : 'Güncel Sürüm Değil'}>
                    <Text type={record.isCurrentVersion ? 'success' : 'warning'}>{item}</Text>
                </Tooltip>
            ),
            categoryType: (item) => (
                item === enumerations.CategoryTypes.Activity ?
                    <CheckCircleTwoTone twoToneColor={"#00C853"} /> :
                    <CloseCircleTwoTone twoToneColor={"#FF1744"} />
            )
        },
        notifications: {
            isRead: (item) => (
                <Tag color={item ? 'green' : 'red'} key={false} bordered={false}>{item ? 'Okundu' : 'Okunmadı'}</Tag>
            ),
        },
        files: {
            orginalFileName: (item, record) => {
                async function handleDownload(guidId, orginalFileName) {
                    await fetch(`${siteConfig.api.file.showFile}?guidId=${guidId}`, buildRequestOptions('GET'))
                        .then((response) => response.blob())
                        .then((blob) => {
                            const contentType = blob.type;

                            const fileURL = URL.createObjectURL(blob);

                            const a = document.createElement('a');
                            a.href = fileURL;
                            a.download = `${orginalFileName}.${getFileTypeExtensionFromContentType(contentType)}`;
                            a.click();

                            URL.revokeObjectURL(fileURL);
                        })
                        .catch((error) => console.error('Dosya indirme hatası:', error));
                }

                return <Button type="link" onClick={() => { handleDownload(record.guidId, item); }}>{item}</Button>
            },
            size: (item) => (
                `${item} KB`
            ),
            reference: (item, record) => {
                if (!record) {
                    return null;
                }

                let content;
                if (typeof record.customer !== 'undefined' && typeof record.period !== 'undefined' && typeof record.tabKey !== 'undefined') {
                    content = <Text>{record.customer.companyName} <Text style={{ marginLeft: '0.2rem' }}>{`->`}</Text> <Button icon={<LinkOutlined />} type="link" danger size="small" disabled href={`/app/${record.customer.id}/dashboard/mainForm#${record.period.id}`}>{record.period.name}</Button> {`->`} <Button icon={<LinkOutlined />} type="link" danger size="small" disabled={typeof tabKey !== 'undefined'} href={`/app/${record.customer.id}/reports/activityData?${URL_PARAMS.PERIOD_ID}=${record.period.id}#${record.tabKey}`}>{translateTabName(record.tabKey)}</Button></Text>;
                } else if (typeof record.customer !== 'undefined' && typeof record.period !== 'undefined') {
                    content = <Text>{record.customer.companyName} <Text style={{ marginLeft: '0.2rem' }}>{`->`}</Text> <Button icon={<LinkOutlined />} type="link" danger size="small" href={`/app/${record.customer.id}/dashboard/mainForm#${record.period.id}`}>{record.period.name}</Button></Text>;
                } else if (typeof record.customer !== 'undefined') {
                    content = <Text>{record.customer.companyName}</Text>;
                } else {
                    content = <Text>Sistem Dosyası</Text>;
                }
                return (
                    content
                );
            }
        }
    },
    titleRenders: {
        general: {
            roleDescription: <Tooltip title="Kullanıcının işlem sırasındaki rolünü göstermektedir. Güncel rolü ile aynı olmayabilir.">Yetki</Tooltip>,
            canCustomerViewFiles: <Tooltip title="Kullanıcıların müşteri dosyalarını görüntüleyebilme ve oluşturabilme yetkisini göstermektedir.">D. İzni</Tooltip>,
            version: <Tooltip title='Projede kullanılan Emisyon Faktörleri sürümünü belirtir'>EF Sürüm</Tooltip>
        }
    }
};

function extendedColumns(columns, pageKey, titleKey = "general", setJson, setVisibleModal) {
    const processColumn = (column) => {
        const updatedColumn = { ...column };

        if (column.type === "number") {
            updatedColumn.render = (item) => numberFormat(item);
        } else if (column.type === "date") {
            updatedColumn.render = (item) => item ? <DateDisplay date={item} format bordered={false} /> : <Tag />;
        } else if (column.type === "percentage") {
            updatedColumn.render = (item) => FormatPercentage(item);
        } else if (column.type === "json" && typeof setJson === 'function' && typeof setVisibleModal === 'function') {
            updatedColumn.render = (item) => <Button shape="circle" onClick={() => { setJson(parseJSON(item)); setVisibleModal(true); }} disabled={item && typeof item.disabledJsonButton === 'boolean' ? item.disabledJsonButton : !item}><ContainerOutlined /></Button>
        }

        const titleRenders = tableFunctions.titleRenders[titleKey]?.[column.key];
        if (titleRenders) {
            updatedColumn.title = titleRenders;
        }

        let renderFunction = tableFunctions.renderFunctions["general"]?.[column.key];
        if (!renderFunction) {
            renderFunction = tableFunctions.renderFunctions[pageKey]?.[column.key];
        }
        if (typeof renderFunction === 'function') {
            updatedColumn.render = renderFunction;
        }

        if (column.children) {
            updatedColumn.children = column.children.map(processColumn);
        }

        return updatedColumn;
    };

    return columns.map(processColumn);
}


export {
    buildRequestOptions,
    checkAccessPermission,
    findMenuKeys,
    isStrInteger,
    titleCase,
    formatCamelToHyphen,
    extendedColumns,
    getSessionProperties,
    handleDateRangeChange,
    mimeToExtension
};