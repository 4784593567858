import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import siteConfig from "@iso/config/site.config";
import history from '@iso/lib/helpers/history';
import { clearLocalStorage } from '@iso/lib/helpers/utility';
import actions from './actions';
import { getSessionProperties } from '../../library/helpers/utils';
import { buildRequestOptions } from '../../library/helpers/utils';
import { apiStatusManagement } from '../../library/helpers/apiStatusManagement';

function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ sessionProperties }) {
    yield put({
      type: actions.SET_SESSION_PROPERTIES,
      sessionProperties,
    });
  });
}

function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {
    yield call(clearLocalStorage);
    if (window.location.pathname !== '/' && window.location.pathname !== '/signin') {
      const pathname = window.location.pathname;
      const search = window.location.search;

      //TODO: In the /kry-life-karbon-ui/node_modules/history/index file, since the forceRefresh: true option is used, the state value is cleared even when a URL push is made in Firefox.
      // When you remove this code in the future, you should also remove the reading from sessionStorage.
      sessionStorage.setItem('redirectFrom', JSON.stringify({ from: { pathname, search } }));

      yield call(() => history.push('/signin', { from: { pathname, search } }));
    }
  });
}

function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    fetch(`${siteConfig.api.security.logout}`, buildRequestOptions("POST", null))
      .then(response => {
        const status = apiStatusManagement(response);
        return status;
      })
      .then(res => {
        if (res) {
          clearLocalStorage();
          history.replace('/');
        }
      })
      .catch(error => console.log(error));
  });
}

function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const sessionProperties = yield call(getSessionProperties);

    if (sessionProperties && sessionProperties.isAuthenticated) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        sessionProperties,
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
